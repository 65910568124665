import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";

import { auth } from "../../firebase/firebase";

import "./Auth.css";

export default function Auth() {
  const [isLogin, setIsLogin] = useState(true);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  const signInEmailRef = useRef();
  const signInPasswordRef = useRef();

  const signUpEmailRef = useRef();
  const signUpPasswordRef = useRef();
  const signUpNameRef = useRef();

  const forgotPasswordRef = useRef();
  const navigate = useNavigate();

  const signupHandler = async (event) => {
    const emailPattern = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;
    const namePattern = /^[a-zA-Z]+\s[a-zA-Z]+$/;

    event.preventDefault();
    try {
      if (isForgotPassword) {
        await sendPasswordResetEmail(auth, forgotPasswordRef.current.value);
        setErrorMessages([]);
        setErrorMessages((data) => {
          return [
            ...data,
            "We sent you an email with a link to reset your password",
          ];
        });
        navigate("/auth");
        return;
      }

      if (isLogin) {
        if (signInEmailRef.current.value === "") {
          setErrorMessages([]);
          setErrorMessages(["Email is required"]);
          return;
        }
        if (!emailPattern.test(signInEmailRef.current.value)) {
          setErrorMessages([]);
          setErrorMessages((data) => {
            return [...data, "Email address is invalid"];
          });
          return;
        }

        if (signInPasswordRef.current.value === "") {
          setErrorMessages([]);
          setErrorMessages((data) => {
            return [...data, "Password is required"];
          });
          return;
        }

        const userData = await signInWithEmailAndPassword(
          auth,
          signInEmailRef.current.value,
          signInPasswordRef.current.value
        );

        if (userData.user.email.includes("admin")) {
          navigate("/dashboard");
        }

        navigate("/scan");

        setErrorMessages([]);
      } else {
        if (signUpEmailRef.current.value === "") {
          setErrorMessages([]);
          setErrorMessages((data) => {
            return [...data, "Email is required"];
          });
          return;
        }

        if (!emailPattern.test(signUpEmailRef.current.value)) {
          setErrorMessages([]);
          setErrorMessages((data) => {
            return [...data, "Email address is invalid"];
          });
          return;
        }

        if (signUpPasswordRef.current.value === "") {
          setErrorMessages([]);
          setErrorMessages((data) => {
            return [...data, "Password is required"];
          });
          return;
        }

        if (signUpNameRef.current.value === "") {
          setErrorMessages([]);
          setErrorMessages((data) => {
            return [...data, "Name is required"];
          });
          return;
        }

        if (!namePattern.test(signUpNameRef.current.value)) {
          setErrorMessages((data) => {
            return [...data, "Name is invalid"];
          });
        }

        //if s/he past valid, then we register the user
        const response = await createUserWithEmailAndPassword(
          auth,
          signUpEmailRef.current.value,
          signUpPasswordRef.current.value
        );

        response.user.displayName = signUpNameRef.current.value;

        setErrorMessages([]);
        setErrorMessages([
          response.user.displayName + " has successfully signed up",
        ]);
        signUpEmailRef.current.value = "";
        signUpPasswordRef.current.value = "";
        signUpNameRef.current.value = "";
      }
    } catch (error) {
      console.log(error.code);
      if (
        error.code === "auth/invalid-credential" ||
        error.code === "auth/user-not-found"
      ) {
        setErrorMessages([]);
        setErrorMessages((data) => {
          return [...data, "Your account does not exist."];
        });
      } else if (error.code === "auth/wrong-password") {
        setErrorMessages([]);
        setErrorMessages((data) => {
          return [...data, "You entered a wrong password"];
        });
      } else if (error.code === "auth/email-already-in-use") {
        setErrorMessages([]);
        setErrorMessages((data) => {
          return [...data, "The email address you entered is already in use."];
        });
      } else if (error.code === "'auth/invalid-email") {
        setErrorMessages([]);
        setErrorMessages((data) => {
          return [...data, "Please enter a valid email address"];
        });
      } else {
        setErrorMessages([]);
        setErrorMessages((data) => {
          return [...data, "Something went wrong with your request"];
        });
      }
    }
  };

  return (
    <div className="signin-form--container">
      <form className="signin-form" onSubmit={signupHandler}>
        {errorMessages.length > 0 &&
          errorMessages.map((errorMessage) => (
            <p className="error-message" key={errorMessage}>
              {errorMessage}
            </p>
          ))}

        {isLogin && !isForgotPassword && (
          <>
            <div className="sign-in">
              <input
                type="email"
                placeholder="Email Address"
                ref={signInEmailRef}
              />
              <input
                type="password"
                placeholder="password"
                ref={signInPasswordRef}
              />
            </div>
          </>
        )}

        {!isLogin && !isForgotPassword && (
          <div className="sign-up">
            <input type="text" placeholder="Name" ref={signUpNameRef} />
            <input
              type="email"
              placeholder="Email Address"
              ref={signUpEmailRef}
            />
            <input
              type="password"
              placeholder="password"
              ref={signUpPasswordRef}
            />
          </div>
        )}

        <div className="no-account-yet"></div>
        <div className="forgot-password">
          {!isForgotPassword && (
            <>
              <p onClick={() => setIsForgotPassword(true)}>Forgot Password?</p>
              <p onClick={() => setIsLogin(false)}>Don't have an account?</p>
            </>
          )}

          {isForgotPassword && (
            <input
              type="email"
              placeholder="Email Address"
              ref={forgotPasswordRef}
            />
          )}
        </div>
        <input type="submit" value={isLogin ? "Sign In" : "Sign Up"} />
      </form>
    </div>
  );
}
