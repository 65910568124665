import React, { useState, useEffect, useRef } from "react";

import { v4 as uuidv4 } from "uuid";

import {
  handleHomePageImageUpload,
  getPhotos,
  deleteHomePageImageFile,
  getHomePageInitialData,
} from "../../firebase/fbcrud";

import { paginatedList } from "../../firebase/pagination";

import "./dashboard.css";

export default function Home() {
  const [imageFile, setImageFile] = useState(null);
  const [photos, setPhotos] = useState([]);

  const [errorMessages, setErrorMessages] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [uploadProgress, setUploadProgress] = useState("");

  const btnNextRef = useRef(null);
  const btnPrevRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [initialData, setInitialData] = useState([]);
  const [photoData, setPhotoData] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        //Get all the photos
        await getPhotos(setPhotos, setErrorMessages);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        //Get only 3 photos
        await getHomePageInitialData("photos", setInitialData);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const onSaveData = async (event) => {
    event.preventDefault();

    if (!imageFile) {
      setErrorMessages((error) => {
        return [...error, "You must upload image of the food"];
      });
      return;
    }

    const docId = uuidv4();
    const filename = uuidv4();

    await handleHomePageImageUpload(
      docId,
      filename,
      imageFile,
      setUploadProgress,
      setErrorMessages,
      setSuccessMessage
    );

    setErrorMessages([]);
  };

  const handleNext = () => {
    setCurrentPage((page) => page + 1);

    const data = paginatedList(
      photos,
      3,
      currentPage,
      btnNextRef,
      btnPrevRef,
      setCurrentPage
    );
    setPhotoData(data);
  };

  const handlePrevious = () => {
    setCurrentPage((page) => page - 1);

    const data = paginatedList(
      photos,
      3,
      currentPage,
      btnNextRef,
      btnPrevRef,
      setCurrentPage
    );
    setPhotoData(data);
  };

  return (
    <div className="dashboard-home">
      {uploadProgress !== "" && (
        <p className="success-message">{uploadProgress}</p>
      )}
      {successMessage !== "" && (
        <p className="success-message">{successMessage.toString()}</p>
      )}

      {errorMessages.length > 0 &&
        errorMessages.map((error) => (
          <p className="error-message" key={error}>
            {error.toString()}
          </p>
        ))}

      <div className="dashboard-home-content">
        <form className="home-upload-form" onSubmit={onSaveData}>
          <label htmlFor="file-upload">Upload an image</label>
          <input
            id="file-upload"
            type="file"
            onChange={(event) => setImageFile(event.target.files[0])}
            accept="image/png, image/jpeg, image/jpg, video/mp4, video/webm"
          />

          <div>
            <input type="submit" value="Submit" />
          </div>
        </form>
      </div>

      {photoData?.length > 0
        ? photoData.map((photo) => (
            <Photo
              btnNextRef={btnNextRef}
              btnPrevRef={btnPrevRef}
              handleNext={handleNext}
              handlePrevious={handlePrevious}
              photo={photo}
              key={photo.id}
              onErrorMessage={setErrorMessages}
              onSuccessMessage={setSuccessMessage}
            />
          ))
        : initialData?.length > 0 &&
          initialData.map((photo) => (
            <Photo
              btnNextRef={btnNextRef}
              btnPrevRef={btnPrevRef}
              handleNext={handleNext}
              handlePrevious={handlePrevious}
              photo={photo}
              key={photo.id}
              onErrorMessage={setErrorMessages}
              onSuccessMessage={setSuccessMessage}
            />
          ))}

      <div className="navigation-button-container">
        <button ref={btnNextRef} onClick={handleNext}>
          Next
        </button>
        <button ref={btnPrevRef} onClick={handlePrevious}>
          previous
        </button>
      </div>
    </div>
  );
}
const handleDelete = (id, imageUrl, setSuccessMessage, setErrorMessages) => {
  deleteHomePageImageFile(id, imageUrl, setSuccessMessage, setErrorMessages);
};

function Photo({ onErrorMessage, onSuccessMessage, photo: { imageUrl, id } }) {
  if (imageUrl.includes(".mp4") || imageUrl.includes(".webm")) {
    return (
      <div className="photo">
        <div className="photo-content">
          <video controls preload="none">
            <source src={imageUrl && imageUrl} />
            <source src={imageUrl && imageUrl} />
          </video>
          <button
            onClick={() =>
              handleDelete(id, imageUrl, onSuccessMessage, onErrorMessage)
            }
          >
            Delete
          </button>
        </div>
      </div>
    );
  }
  return (
    <div className="photo">
      <div className="photo-content">
        <img
          src={imageUrl && imageUrl}
          alt="Rapadou Caribbean Restaurant"
          loading="lazy"
        />
        <button
          onClick={() =>
            handleDelete(id, imageUrl, onSuccessMessage, onErrorMessage)
          }
        >
          Delete
        </button>
      </div>
    </div>
  );
}
