import menu1 from "../../assets/images/MENU-01.jpg";
import menu2 from "../../assets/images/MENU-02.jpg";
import menu3 from "../../assets/images/MENU-03.jpg";
import menu4 from "../../assets/images/MENU-04.jpg";

import "./Menu.css";

export default function Menu() {
  return <LoadImages />;
}

function LoadImages() {
  const images = [menu1, menu2, menu3, menu4];
  return (
    <div className="load-image-container">
      {images.map((image) => (
        <div className="menu-image-container" key={image}>
          <img src={image} alt="Rapadou Caribbean Restaurant" loading="lazy" />
        </div>
      ))}
    </div>
  );
}
