import React from "react";
import { IoMdClose } from "react-icons/io";

import "./Popup.css";

export default function Popup({ message, onIsAdded }) {
  return (
    <div className="pop-up">
      <IoMdClose className="popup-close" onClick={() => onIsAdded(false)} />
      <p>{message}</p>
    </div>
  );
}
