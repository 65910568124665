import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";

import { auth } from "../../firebase/firebase";

import "./OrdersCard.css";
function OrdersCard({
  orderData,
  onHandleProcessOrder,
  onOrderComplete,
  isBegin,
  isComplete,
}) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(user ? true : false);

      return () => unsub();
    });
  }, []);

  return isAuthenticated ? (
    <div className="order-card-container">
      {orderData &&
        orderData.length > 0 &&
        orderData?.map((data) => {
          return data.orders.map((order) => {
            return (
              <div
                key={order.id}
                className={`order-card cart-item-container ${
                  data?.isFlashOrder ? "blink_new_order" : ""
                }`}
              >
                <div
                  className="cart-item-container"
                  onClick={() => console.log("order id ", order.id)}
                >
                  <div className="order-description-container">
                    <p>{order.description}</p>
                    <h3>&#36; {order.price}</h3>
                  </div>
                  <div className="image-container">
                    <img
                      src={order.imageUrl}
                      alt="food"
                      width="25rem"
                      height="20rem"
                      loading="lazy"
                    />
                  </div>
                  <div className="btn-cooking-container">
                    {isBegin && (
                      <button
                        onClick={onHandleProcessOrder.bind(
                          this,
                          data.id,
                          order.id
                        )}
                      >
                        Begin
                      </button>
                    )}
                    {isComplete && (
                      <button onClick={onOrderComplete.bind(this, order.id)}>
                        Complete
                      </button>
                    )}
                  </div>
                </div>

                <main
                  className={`order-sides ${data?.isFlashOrder && "blink_me"}`}
                >
                  {order.sideDetails.length > 0 &&
                    order.sideDetails.map((side) => (
                      <div key={side}>
                        <p>{side}</p>
                      </div>
                    ))}
                </main>
              </div>
            );
          });
        })}
    </div>
  ) : (
    navigate("/auth")
  );
}

export default OrdersCard;
