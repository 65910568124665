import React from "react";

import "./inventoryRow.css";

export default function InventoryRow({
  id,
  name,
  price,
  quantity,
  onIncDecQuantity,
}) {
  return (
    <tr>
      <td>{name}</td>
      <td>{price}</td>
      <td>{quantity}</td>
      <td>
        <button onClick={onIncDecQuantity.bind(this, id, "increment")}>
          +
        </button>
        <button onClick={onIncDecQuantity.bind(this, id, "decrement")}>
          -
        </button>
      </td>
    </tr>
  );
}
