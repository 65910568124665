import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { CiMenuBurger } from "react-icons/ci";
import { onAuthStateChanged, signOut } from "firebase/auth";

import { auth } from "../../firebase/firebase";

import logo from "../../assets/images/logo.png";
import "./Navigation.css";

export default function Navigation() {
  const navigate = useNavigate();
  const [isBurgerMenu, setIsBurgerMenu] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showSignIn, setShowSignIn] = useState(false);

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(user ? true : false);
      setIsAdmin(user?.email.includes("admin"));
      return () => unsub();
    });
  }, []);

  return (
    <header className="navigation-container">
      <nav className="main-nav">
        <ul className="menu-list">
          <li
            className="menu-list-item"
            onClick={() => {
              setShowSignIn((prev) => (prev = !prev));
              navigate("/");
            }}
          >
            <img src={logo} alt="Rapadou Logo" />
          </li>

          <li className="menu-list-item">
            <NavLink
              to="/"
              className={({ isActive }) => (isActive ? "active" : undefined)}
              end
            >
              Home
            </NavLink>
          </li>
          {(!isAuthenticated || !isAdmin) && (
            <li className="menu-list-item">
              <NavLink
                to="/menu"
                className={({ isActive }) => (isActive ? "active" : undefined)}
                end
              >
                Menu
              </NavLink>
            </li>
          )}

          {(isAuthenticated || isAdmin) && (
            <li className="menu-list-item">
              <NavLink
                to="/scan"
                className={({ isActive }) => (isActive ? "active" : undefined)}
              >
                Scan
              </NavLink>
            </li>
          )}
          {(isAuthenticated || isAdmin) && (
            <li className="menu-list-item">
              <NavLink
                to="/orders"
                className={({ isActive }) => (isActive ? "active" : undefined)}
              >
                Orders
              </NavLink>
            </li>
          )}

          {(isAuthenticated || isAdmin) && (
            <li className="menu-list-item">
              <NavLink
                to="/foodmenu"
                className={({ isActive }) => (isActive ? "active" : undefined)}
              >
                Menu
              </NavLink>
            </li>
          )}

          {isAdmin && (
            <li className="menu-list-item">
              <NavLink
                to="/dashboard"
                className={({ isActive }) => (isActive ? "active" : undefined)}
              >
                Dashboard
              </NavLink>
            </li>
          )}

          <li className="menu-list-item">
            {isAuthenticated || isAdmin ? (
              <NavLink
                onClick={() => signOut(auth)}
                className={({ isActive }) => (isActive ? "active" : undefined)}
              >
                Sign Out
              </NavLink>
            ) : (
              <NavLink
                to="/auth"
                className={({ isActive }) => (isActive ? "active" : undefined)}
              >
                {showSignIn ? "Sign In" : undefined}
              </NavLink>
            )}
          </li>
        </ul>

        <div className="hamburger-menu-container">
          <div className="hamburger-logo-burger-container">
            <div className="hamburger-logo-container">
              <img src={logo} alt="Rapadou Logo" />
            </div>
            <CiMenuBurger
              className="hamburger-menu-icon"
              onClick={() => setIsBurgerMenu(!isBurgerMenu)}
            />
          </div>
          {isBurgerMenu && (
            <ul className="hamburger-menu-list">
              <li className="menu-list-item">
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    isActive ? "active" : undefined
                  }
                  end
                >
                  Home
                </NavLink>
              </li>

              {(!isAuthenticated || !isAdmin) && (
                <li className="menu-list-item">
                  <NavLink
                    to="/menu"
                    className={({ isActive }) =>
                      isActive ? "active" : undefined
                    }
                    end
                  >
                    Menu
                  </NavLink>
                </li>
              )}

              {(isAuthenticated || isAdmin) && (
                <li className="menu-list-item">
                  <NavLink
                    to="/scan"
                    className={({ isActive }) =>
                      isActive ? "active" : undefined
                    }
                  >
                    Scan
                  </NavLink>
                </li>
              )}

              {(isAuthenticated || isAdmin) && (
                <li className="menu-list-item">
                  <NavLink
                    to="/orders"
                    className={({ isActive }) =>
                      isActive ? "active" : undefined
                    }
                  >
                    Orders
                  </NavLink>
                </li>
              )}

              {(isAuthenticated || isAdmin) && (
                <li className="menu-list-item">
                  <NavLink
                    to="/foodmenu"
                    className={({ isActive }) =>
                      isActive ? "active" : undefined
                    }
                  >
                    Menu
                  </NavLink>
                </li>
              )}

              {isAdmin && (
                <li className="menu-list-item">
                  <NavLink
                    to="/dashboard"
                    className={({ isActive }) =>
                      isActive ? "active" : undefined
                    }
                  >
                    Dashboard
                  </NavLink>
                </li>
              )}

              <li className="menu-list-item">
                {isAuthenticated || isAdmin ? (
                  <NavLink
                    onClick={() => signOut(auth)}
                    className={({ isActive }) =>
                      isActive ? "active" : undefined
                    }
                  >
                    Sign Out
                  </NavLink>
                ) : (
                  <NavLink
                    to="/auth"
                    className={({ isActive }) =>
                      isActive ? "active" : undefined
                    }
                  >
                    Sign In
                  </NavLink>
                )}
              </li>
            </ul>
          )}
        </div>
      </nav>
    </header>
  );
}
