import React from "react";
import Home from "./pages/Home";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

import QRcodegenerator from "./components/qrcodegenerator/QRcodegenerator";
import QRcodescanner from "./components/qrcoderscanner/QRcodescanner";
import Weather from "./components/weather/Weather";
import Dashboard from "./components/dashboard/Dashboard";
import Menu from "./pages/Menu";
import CustomerMenu from "./components/menu/Menu";
import Auth from "./components/auth/Auth";
import Orders from "./pages/Orders";
import { RootLayout } from "./pages/RootLayout";
import ErrorPage from "./pages/ErrorPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/qrcode",
        element: <QRcodegenerator />,
      },
      {
        path: "/scan",
        element: <QRcodescanner />,
      },

      {
        path: "/weather",
        element: <Weather />,
      },

      {
        path: "/dashboard",
        element: <Dashboard />,
      },

      {
        path: "/foodmenu",
        element: <Menu />,
      },
      {
        path: "/menu",
        element: <CustomerMenu />,
      },

      {
        path: "/auth",
        element: <Auth />,
      },

      {
        path: "/orders",
        element: <Orders />,
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
