import React from "react";

import "./FoodSide.css";

function FoodSide({ sides, setSideDetails, onIsAdded }) {
  return (
    <div className="food-side">
      {sides?.length > 0 &&
        sides.map(({ side, sideImageUrl }) => {
          return (
            <div
              key={side}
              onClick={() => {
                setSideDetails((details) => [...details, side]);
              }}
              className="food-side-item-container"
            >
              <p>{side}</p>
              <div className="food-side-item-img-container">
                <img
                  src={sideImageUrl}
                  alt="Rapadou Menu"
                  className="food-side-item-img"
                  loading="lazy"
                />
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default React.memo(FoodSide);
