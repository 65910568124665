import React, { useState, useEffect } from "react";
import { FaHome } from "react-icons/fa";

import { onAuthStateChanged, signOut } from "firebase/auth";

import { auth } from "../../firebase/firebase";
import Home from "./Home";
import Category from "./Category";
import QrCode from "./QRcode";
import CompletedOrders from "./CompletedOrders";
import Inventory from "./Inventory";

import "./dashboard.css";

export default function Dashboard() {
  const [isHome, setIsHome] = useState(false);
  const [isCategory, setIsCategory] = useState(false);
  const [isQrCode, setIsQrCode] = useState(false);
  const [isInventory, setIsInventory] = useState(false);
  const [isCompletedOrder, setIsCompletedOrder] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, (user) => {
      setIsAdmin(user.email.includes("admin"));

      return () => unsub();
    });
  }, []);

  return isAdmin ? (
    <div className="dashboard">
      <aside className="left-side">
        <ul>
          <li
            onClick={() => {
              setIsHome(true);
              setIsCategory(false);
              setIsQrCode(false);
              setIsInventory(false);
              setIsCompletedOrder(false);
            }}
          >
            <FaHome size={30} />
          </li>

          <li
            onClick={() => {
              setIsCategory(true);
              setIsHome(false);
              setIsQrCode(false);
              setIsInventory(false);
              setIsCompletedOrder(false);
            }}
          >
            Categories
          </li>
          <li
            onClick={() => {
              setIsQrCode(true);
              setIsHome(false);
              setIsCategory(false);
              setIsInventory(false);
              setIsCompletedOrder(false);
            }}
          >
            QR Code
          </li>
          <li
            onClick={() => {
              setIsQrCode(false);
              setIsHome(false);
              setIsCategory(false);
              setIsInventory(false);
              setIsCompletedOrder(true);
            }}
          >
            Completed Order
          </li>

          <li
            onClick={() => {
              setIsQrCode(false);
              setIsHome(false);
              setIsCategory(false);
              setIsInventory(true);
              setIsCompletedOrder(false);
            }}
          >
            Inventory
          </li>
          <li onClick={() => signOut(auth)}>Logout</li>
        </ul>
      </aside>

      <main className="right-side">
        {isHome && <Home onIsHome={setIsHome} />}
        {isCategory && <Category onIsCategory={setIsCategory} />}
        {isQrCode && <QrCode />}
        {isCompletedOrder && <CompletedOrders />}
        {isInventory && <Inventory />}
      </main>
    </div>
  ) : (
    <p className="permission">
      You don't have proper permissions to view this page
    </p>
  );
}
