import React from "react";
import "./updatecategory.css";

function Updatecategory(props) {
  const {
    docId,
    updateTypeRef,
    updatePriceRef,
    updateDescriptionRef,
    onUpdateFoodMenu,
    onDeleteFoodMenu,
    isDeleting,
    isUpdating,
    setImageFile,
  } = props;

  return (
    <td colSpan="4">
      <div>
        <div className="update-form">
          {!isDeleting && (
            <>
              <input
                type="text"
                placeholder="Update type Of food"
                ref={updateTypeRef}
              />
              <input
                type="number"
                placeholder="Update price"
                ref={updatePriceRef}
              />
              <textarea
                placeholder="Update description"
                ref={updateDescriptionRef}
              ></textarea>

              <label htmlFor="update-upload">
                Upload
                <input
                  id="update-upload"
                  type="file"
                  onChange={(event) => setImageFile(event.target.files[0])}
                  accept="image/png, image/jpeg"
                />
              </label>
            </>
          )}
          <div className="btn-update-delete">
            {!isDeleting && (
              <button onClick={onUpdateFoodMenu.bind(this, docId)}>
                Update
              </button>
            )}

            {!isUpdating && (
              <button onClick={onDeleteFoodMenu.bind(this, docId)}>
                Delete
              </button>
            )}
          </div>
        </div>
      </div>
    </td>
  );
}
export default React.memo(Updatecategory);
