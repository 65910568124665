import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import QrScanner from "qr-scanner";
import { IoMdQrScanner } from "react-icons/io";

import "./QRcodescanner.css";

export default function QRcodescanner() {
  const videoRef = useRef(null);
  const qrBoxRef = useRef(null);

  const [scanError, setScanError] = useState(false);
  const navigate = useNavigate();

  const success = (result) => {
    if (result?.data !== "") setScanError(false);

    navigate("/foodmenu", { state: { result } });
  };

  const failure = (error) => {
    if (error !== "") setScanError(true);
  };
  const scan = () => {
    const qrScanner = new QrScanner(videoRef.current, success, {
      returnDetailedScanResult: true,
      onDecodeError: failure,
    });

    qrScanner.start();
  };

  return (
    <div className="qr-scanner">
      <div ref={qrBoxRef} className="qr-box">
        <p> {scanError ? "Please point camera directly at QR Code" : null}</p>
        <video ref={videoRef}></video>
        <div>
          <IoMdQrScanner size={180} onClick={scan} />
        </div>
      </div>
    </div>
  );
}
