// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC8AY71twz5vFyhq2nB7vt7ySferz3aqEc",
  authDomain: "rapadou-237ca.firebaseapp.com",
  projectId: "rapadou-237ca",
  storageBucket: "rapadou-237ca.appspot.com",
  messagingSenderId: "29291689759",
  appId: "1:29291689759:web:a1dfde46c5bbe45c98e22a",
  measurementId: "G-36PFECV9BE",
};

// Initialize Firebase
initializeApp(firebaseConfig);

export const auth = getAuth();
export const db = getFirestore();
export const storage = getStorage();
