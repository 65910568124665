import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";

import {
  setDoc,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  updateDoc,
  collection,
  onSnapshot,
  query,
  limit,
} from "firebase/firestore";

import { db, storage } from "./firebase";

export const handleImageUpload = async (
  filename,
  file,
  foodtype,
  price,
  description,
  docId,
  isUpdate = false, //Yes, we added a flag here to check whether user is updating or saving - by default, we set it false to make the param optional
  isImage = false, //Yes, we added a flag here to check whether user is  uploading an image or not y.
  onIsCategory = false,
  setUploadProgress,
  setErrorMessages,
  setSuccessMessage,
  updateTypeRef = null,
  updatePriceRef = null,
  updateDescriptionRef = null,
  foodTypeRef = null,
  foodPriceRef = null,
  foodDescriptionRef = null,
  isFoodSide = false,
  setFoodSide,
  foodSide = [],
  sideDescription = ""
) => {
  // Create the file metadata
  /** @type {any} */
  const metadata = {
    contentType: "image/jpeg",
    firebaseStorageDownloadTokens: filename,
  };
  try {
    // Upload file and metadata to the object 'images/mountains.jpg'
    const storageRef = ref(storage, "images/" + `${filename}.jpg`);
    const uploadTask = uploadBytesResumable(storageRef, file, metadata);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        if (typeof progress === Number) {
          setUploadProgress("Upload is " + progress + "% done");
        }
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            setSuccessMessage("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            setSuccessMessage("Upload is running");
            break;
          default:
            console.log("Be patient!!!");
        }
      },
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            setErrorMessages([]);
            setErrorMessages((errorfb) => {
              return [
                ...errorfb,
                "User doesn't have permission to access the object",
              ];
            });
            break;
          case "storage/canceled":
            // User canceled the upload
            setErrorMessages([]);
            setErrorMessages((errorfb) => {
              return [...errorfb, "User canceled the upload"];
            });
            break;

          // ...

          case "storage/unknown":
            // Unknown error occurred, inspect error.serverResponse
            setErrorMessages([]);
            setErrorMessages((errorfb) => {
              return [
                ...errorfb,
                "Unknown error occurred, inspect error.serverResponse",
              ];
            });
            break;

          default:
            setErrorMessages([]);
            setErrorMessages((errorfb) => {
              return [...errorfb, "Server error occurred"];
            });
        }
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          //console.log("File available at", downloadURL);

          if (isUpdate) {
            const updateFoodRef = doc(db, "foodmenu", docId);
            //You must upate conditionally to prevent empty entries

            if (isImage) {
              updateDoc(updateFoodRef, {
                imageUrl: downloadURL,
              });

              if (foodtype !== "") {
                updateDoc(updateFoodRef, {
                  category: foodtype,
                });
              }

              if (price !== "") {
                updateDoc(updateFoodRef, {
                  price: price,
                });
              }

              if (description !== "") {
                updateDoc(updateFoodRef, {
                  description: description,
                });
              }

              setSuccessMessage("");
              setSuccessMessage("Upload is complete");
            }

            //You must upate conditionally to prevent empty entries
            if (foodtype !== "") {
              updateDoc(updateFoodRef, {
                category: foodtype,
              });
            }

            if (price !== "") {
              updateDoc(updateFoodRef, {
                price: price,
              });
            }

            if (description !== "") {
              updateDoc(updateFoodRef, {
                description: description,
              });
            }

            setSuccessMessage("");
            setSuccessMessage("Upload is complete");
            onIsCategory(true);

            return;
          }
          if (isFoodSide) {
            setFoodSide((fside) => [
              ...fside,
              {
                id: docId,
                side: sideDescription,
                sideImageUrl: downloadURL,
              },
            ]);

            return;
          }

          setDoc(doc(db, "foodmenu", docId), {
            imageUrl: downloadURL,
            category: foodtype,
            price: price,
            description: description,
            sides: foodSide, //sideDescriptionRef.current.value || "",
            //sideImageUrl: sideImageUrl,
          });
          setSuccessMessage("Upload is complete");
          onIsCategory(true); //set isCategory to true so that it shows/refresh the category component again.
          foodTypeRef.current.value = "";
          foodPriceRef.current.value = "";
          foodDescriptionRef.current.value = "";
        });
      }
    );
  } catch (error) {
    console.log(error);
  }
};

export function deleteImageFile(imageUrl, setSuccessMessage, setErrorMessages) {
  // Create a reference to the file to delete
  const desertRef = ref(storage, imageUrl);

  // Delete the file
  deleteObject(desertRef)
    .then(() => {
      // File deleted successfully
      setSuccessMessage("File deleted successfully");
    })
    .catch((error) => {
      // Uh-oh, an error occurred!
      setErrorMessages((errorlocal) => {
        return [...errorlocal, error];
      });
      return;
    });
}

export function deleteSideImages(sides, setSuccessMessage, setErrorMessages) {
  try {
    if (Array.isArray(sides)) {
      let desertRef = null;

      sides.forEach((side) => {
        desertRef = ref(storage, side?.sideImageUrl);

        deleteObject(desertRef)
          .then(() => {
            // File deleted successfully
            setSuccessMessage("File deleted successfully");
          })
          .catch((error) => {
            // Uh-oh, an error occurred!
            setErrorMessages((errorlocal) => {
              return [...errorlocal, error];
            });
            return;
          });
      });
    }
  } catch (error) {
    console.log(error);
  }
}

//Inventory
export async function saveInventory(
  id,
  name,
  price,
  quantity,
  setConfirmationMessage
) {
  try {
    await setDoc(doc(db, "inventory", id), {
      name,
      price,
      quantity,
    });
    setConfirmationMessage("Inventory record was successfully stored.");
  } catch (error) {
    console.log(error);
  }
}

export function getAllData(dbname, setAllData) {
  try {
    const q = query(collection(db, dbname));

    onSnapshot(q, (querySnapshot) => {
      const data = [];
      querySnapshot.docs.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });

      setAllData(data);
    });
  } catch (error) {
    console.log(error);
  }
}

export function getInitialData(dbname, setInitialData) {
  try {
    const q = query(collection(db, dbname), limit(3));

    onSnapshot(q, (querySnapshot) => {
      const data = [];
      querySnapshot.docs.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });

      setInitialData(data);
    });
  } catch (error) {
    console.log(error);
  }
}

async function getQuantity(id) {
  try {
    const quantityRef = doc(db, "inventory", id);
    const docSnap = await getDoc(quantityRef);
    if (docSnap.exists()) {
      return docSnap.data().quantity;
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }

    return docSnap;
  } catch (error) {
    console.log(error);
  }
}

export async function updateIncDecQuantity(id, flag, setMessage) {
  try {
    let quantity = await getQuantity(id);

    if (flag === "increment") {
      quantity++;
    }

    if (flag === "decrement") {
      quantity--;
    }
    const quantityRef = doc(db, "inventory", id);
    updateDoc(quantityRef, {
      quantity,
    });
    setMessage("Quantity was updated.");
  } catch (error) {
    console.log(error);
  }
}

/*
Home Page Food Gallery
*/

export const getPhotos = async (setData, setErrorMessages) => {
  try {
    let datarr = [];
    let data = {};
    const querySnapshot = await getDocs(collection(db, "photos"));
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      //if you recall, the id is always seperate from the data, you have to get it from the document....

      data = { ...doc.data(), id: doc.id }; //unpack doc.data() and add the id

      datarr.push(data);
    });

    setData(datarr);
  } catch (error) {
    console.log(error);
    setErrorMessages(error);
  }
};

export const handleHomePageImageUpload = async (
  docId,
  filename,
  file,
  setUploadProgress,
  setErrorMessages,
  setSuccessMessage
) => {
  try {
    // Upload file and metadata to the object 'images/mountains.jpg'

    let storageRef = ref(storage, "homeimages/" + `${filename}`);

    if (file.name.includes(".mp4")) {
      storageRef = ref(storage, "homeimages/" + `${filename}.mp4`);
    }

    if (file.name.includes(".webm")) {
      storageRef = ref(storage, "homeimages/" + `${filename}.webm`);
    }

    const uploadTask = uploadBytesResumable(storageRef, file);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        if (typeof progress === Number) {
          setUploadProgress("Upload is " + progress + "% done");
        }
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            setSuccessMessage("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            setSuccessMessage("Upload is running");
            break;
          default:
            console.log("Be patient!!!");
        }
      },
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            setErrorMessages([]);
            setErrorMessages((errorfb) => {
              return [
                ...errorfb,
                "User doesn't have permission to access the object",
              ];
            });
            break;
          case "storage/canceled":
            // User canceled the upload
            setErrorMessages([]);
            setErrorMessages((errorfb) => {
              return [...errorfb, "User canceled the upload"];
            });
            break;

          // ...

          case "storage/unknown":
            // Unknown error occurred, inspect error.serverResponse
            setErrorMessages([]);
            setErrorMessages((errorfb) => {
              return [
                ...errorfb,
                "Unknown error occurred, inspect error.serverResponse",
              ];
            });
            break;

          default:
            setErrorMessages([]);
            setErrorMessages((errorfb) => {
              return [...errorfb, "Server error occurred"];
            });
        }
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          //console.log("File available at", downloadURL);

          setDoc(doc(db, "photos", docId), {
            imageUrl: downloadURL,
          });
          setSuccessMessage("Upload is complete");
        });
      }
    );
  } catch (error) {
    console.log(error);
  }
};

export function deleteHomePageImageFile(
  id,
  imageUrl,
  setSuccessMessage,
  setErrorMessages
) {
  try {
    // Create a reference to the file to delete
    const desertRef = ref(storage, imageUrl);

    // Delete the file
    deleteObject(desertRef)
      .then(async () => {
        // File deleted successfully
        setSuccessMessage("File deleted successfully");
        await deleteHomePageImageRecord(id);
      })
      .catch((error) => {
        // Uh-oh, an error occurred!
        setErrorMessages((errorlocal) => {
          return [...errorlocal, error];
        });
        return;
      });
  } catch (error) {
    console.log(error);
  }
}

export async function deleteHomePageImageRecord(id) {
  //Delete record from firestore
  await deleteDoc(doc(db, "photos", id));
}

//GetData with OnSnapShot
export function getAllHomeImageData(dbname, setAllData, setErrorMessages) {
  try {
    const q = query(collection(db, dbname));

    onSnapshot(q, (querySnapshot) => {
      const data = [];
      querySnapshot.docs.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });

      setAllData(data);
    });
  } catch (error) {
    console.log(error);
  }
}

export function getHomePageInitialData(dbname, setInitialData) {
  try {
    const q = query(collection(db, dbname), limit(3));

    onSnapshot(q, (querySnapshot) => {
      const data = [];
      querySnapshot.docs.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });

      setInitialData(data);
    });
  } catch (error) {
    console.log(error);
  }
}
