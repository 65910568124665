import {
  collection,
  query,
  orderBy,
  limit,
  startAfter,
  endBefore,
  limitToLast,
  getDocs,
  getCountFromServer,
} from "firebase/firestore";

import { db } from "./firebase";

export const getPaginatedData = async (
  collectionS,
  orderByProp,
  direction,
  startAfterDoc,
  endBeforeDoc,
  numPerPage
) => {
  const dataCollection = collection(db, collectionS);

  // Define the initial data query with ordering and limit
  let dataQuery = query(
    dataCollection,
    orderBy(orderByProp),
    limit(numPerPage)
  );

  // Update query based on direction and provided documents
  if (direction === "next" && startAfterDoc) {
    // For next direction, start after the provided document
    dataQuery = query(dataQuery, startAfter(startAfter));
  } else if (direction === "prev" && endBefore) {
    // For previous direction, end before the provided document and limit to last
    dataQuery = query(
      dataCollection,
      orderBy(orderByProp),
      endBefore(endBeforeDoc),
      limitToLast(numPerPage)
    );
  }

  // Get snapshot of documents based on the final query
  const productsSnapshot = await getDocs(dataQuery);

  // Extract data from each document and map to an array of objects
  const products = productsSnapshot.docs.map((doc) => doc.data());

  // Return an object retrieved products, and first and last documents
  return {
    result: products,
    lastDoc: productsSnapshot.docs[productsSnapshot.docs.length - 1],
    firstDoc: productsSnapshot.docs[0],
  };
};

export const getPaginatedDataForOnSnapShot = async (
  collectionS,
  orderByProp,
  direction,
  startAfterDoc,
  endBeforeDoc,
  numPerPage
) => {
  const dataCollection = collection(db, collectionS);

  // Define the initial data query with ordering and limit
  let dataQuery = query(
    dataCollection,
    orderBy(orderByProp),
    limit(numPerPage)
  );

  // Update query based on direction and provided documents
  if (direction === "next" && startAfterDoc) {
    // For next direction, start after the provided document
    dataQuery = query(dataQuery, startAfter(startAfter));
  } else if (direction === "prev" && endBefore) {
    // For previous direction, end before the provided document and limit to last
    dataQuery = query(
      dataCollection,
      orderBy(orderByProp),
      endBefore(endBeforeDoc),
      limitToLast(numPerPage)
    );
  }

  // Get snapshot of documents based on the final query
  //const productsSnapshot = await getDocs(dataQuery);

  // Extract data from each document and map to an array of objects
  //const products = productsSnapshot.docs.map((doc) => doc.data());

  // Return an object retrieved products, and first and last documents
  return dataQuery;
};
//For getting the number of pages from Firebase without get all the data, using getCountFromServer from firebase/firestore.

export const getNumPages = async (collectionName, numPerPages) => {
  const dataCollection = collection(db, collectionName);
  const count = await getCountFromServer(dataCollection);
  const numPages = Math.ceil(count.data().count / numPerPages);
  return numPages;
};

export const paginatedList = (
  items,
  rows_per_page,
  page,
  btnNextRef,
  btnPrevRef,
  setCurrentPage
) => {
  try {
    let loop_start = rows_per_page * page;
    let loop_end = loop_start + rows_per_page;

    if (btnNextRef.current.style.display === "none") {
      btnNextRef.current.style.display = "block";
    }

    if (btnPrevRef.current.style.display === "none") {
      btnPrevRef.current.style.display = "block";
    }

    if (loop_start >= items?.length) {
      setCurrentPage(0);
      btnNextRef.current.style.display = "none";
      return;
    }

    if (loop_start < 0) {
      setCurrentPage(0);
      btnPrevRef.current.style.display = "none";
      return;
    }

    let paginatedItems = items?.slice(loop_start, loop_end);

    return paginatedItems;
  } catch (error) {
    console.log(error);
  }
};
