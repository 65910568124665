import React, { useRef, useState, useEffect } from "react";

import {
  collection,
  onSnapshot,
  query,
  limit,
  where,
} from "firebase/firestore";

import { db } from "../../firebase/firebase";
import { paginatedList } from "../../firebase/pagination";

import "./dashboard.css";

export default function CompletedOrders() {
  const btnNextRef = useRef(null);
  const btnPrevRef = useRef(null);

  const [orderData, setOrderData] = useState([]);
  const [initialData, setInitialData] = useState([]);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    try {
      const q = query(
        collection(db, "orders"),
        where("orderCompleted", "==", true)
      );

      onSnapshot(q, (querySnapshot) => {
        const data = [];
        querySnapshot.docs.forEach((doc) => {
          data.push({ ...doc.data(), id: doc.id });
        });

        setOrderData(data);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    try {
      const q = query(
        collection(db, "orders"),
        limit(3),
        where("orderCompleted", "==", true)
      );

      onSnapshot(q, (querySnapshot) => {
        const data = [];
        querySnapshot.docs.forEach((doc) => {
          data.push({ ...doc.data(), id: doc.id });
        });

        setInitialData(data);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleNext = () => {
    setCurrentPage((page) => page + 1);

    const data = paginatedList(
      orderData,
      3,
      currentPage,
      btnNextRef,
      btnPrevRef,
      setCurrentPage
    );
    setData(data);
  };

  const handlePrevious = () => {
    setCurrentPage((page) => page - 1);

    const data = paginatedList(
      orderData,
      3,
      currentPage,
      btnNextRef,
      btnPrevRef,
      setCurrentPage
    );
    setData(data);
  };

  return (
    <div className="category-view">
      <table>
        <thead>
          <tr>
            <td>Table Number</td>
            <td>Food Type</td>
            <td>Description</td>
            <td>Price</td>
            <td>Date & Time</td>
          </tr>
        </thead>
        <tbody>
          {data?.length > 0
            ? data.map((cdata) => {
                return cdata.orders.map(
                  ({
                    category,
                    description,
                    price,
                    tableNum,
                    datetimecompleted,
                  }) => {
                    return (
                      <tr key={datetimecompleted}>
                        <td>{tableNum}</td>
                        <td>{category}</td>
                        <td>{description}</td>
                        <td>{price}</td>
                        <td>
                          {new Date(cdata.datetimecompleted).toLocaleString()}
                        </td>
                      </tr>
                    );
                  }
                );
              })
            : initialData.length > 0 &&
              initialData.map((cdata) => {
                return cdata.orders.map(
                  ({ category, description, price, tableNum }) => {
                    return (
                      <tr key={price}>
                        <td>{tableNum}</td>
                        <td>{category}</td>
                        <td>{description}</td>
                        <td>{price}</td>
                        <td>
                          {new Date(cdata.datetimecompleted).toLocaleString()}
                        </td>
                      </tr>
                    );
                  }
                );
              })}
          <tr>
            <td className="next-previous-wrapper">
              <button onClick={handleNext} ref={btnNextRef}>
                Next
              </button>
              <button onClick={handlePrevious} ref={btnPrevRef}>
                Previous
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
