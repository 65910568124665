import React from "react";
import { RiDeleteBin2Fill } from "react-icons/ri";

import "./CartCard.css";

function CartCard({
  onRemoveFromCart,
  order: { id, tableNum, category, price, sideDetails },
}) {
  return (
    <div className="cart-card">
      <div className="item-container">
        <p>{tableNum}</p>
        <p>{category}</p>
        <p>{price}</p>
        {sideDetails.map((side) => (
          <p>{side}</p>
        ))}
        <button
          className="delete-order"
          onClick={() =>
            onRemoveFromCart({
              id,
              tableNum,
              category,
              price,
              sideDetails,
            })
          }
        >
          <RiDeleteBin2Fill className="cart-icon" />
        </button>
      </div>
    </div>
  );
}

export default React.memo(CartCard);
