import React, { useState } from "react";
import Updatecategory from "./Updatecategory";

import "./Row.css";

function Row(props) {
  const {
    data: { id, category, price, description, imageUrl, sides },
    isUpdate,
    onIsUpdate,
    updateTypeRef,
    updatePriceRef,
    updateDescriptionRef,
    updateImageRef,
    onUpdateFoodMenu,
    onDeleteFoodMenu,
    setImageFile,
  } = props;

  const [idClicked, setIdClicked] = useState("");
  const [idToDeleteClicked, setIdToDeleteClicked] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  return (
    <>
      <tr>
        {isUpdate && (
          <>
            <td>{category}</td>
            <td>{price}</td>
            <td>{description}</td>
            <td>
              <img
                src={imageUrl}
                className="imgfood"
                alt="Rapadou Menu"
                loading="lazy"
              />
            </td>

            {Array.isArray(sides) &&
              sides.length > 0 &&
              sides.map(({ side, sideImageUrl }) => {
                return (
                  <div key={sideImageUrl}>
                    <td>{side}</td>
                    <td>
                      <img
                        src={sideImageUrl}
                        className="imgfood"
                        alt="Rapadou Menu"
                        loading="lazy"
                      />
                    </td>
                  </div>
                );
              })}

            <td>
              <button
                onClick={() => {
                  setIdClicked(id);
                  onIsUpdate(!isUpdate);
                  setIsUpdating(true);
                }}
              >
                Update
              </button>
            </td>
            <td>
              <button
                onClick={() => {
                  setIdToDeleteClicked(id);
                  setIsDeleting(true);
                  onIsUpdate(!isUpdate);
                }}
              >
                Delete
              </button>
            </td>
          </>
        )}
        {idClicked === id && (
          <Updatecategory
            docId={id}
            updateTypeRef={updateTypeRef}
            updatePriceRef={updatePriceRef}
            updateDescriptionRef={updateDescriptionRef}
            updateImageRef={updateImageRef}
            onUpdateFoodMenu={onUpdateFoodMenu}
            setImageFile={setImageFile}
            isUpdating={isUpdating}
          />
        )}

        {idToDeleteClicked === id && (
          <Updatecategory
            docId={id}
            updateTypeRef={updateTypeRef}
            updatePriceRef={updatePriceRef}
            updateDescriptionRef={updateDescriptionRef}
            updateImageRef={updateImageRef}
            onDeleteFoodMenu={onDeleteFoodMenu}
            isDeleting={isDeleting}
          />
        )}
      </tr>
    </>
  );
}
export default React.memo(Row);
