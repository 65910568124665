import React, { useEffect, useState } from "react";
import {
  collection,
  updateDoc,
  doc,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";

import { db } from "../firebase/firebase";
import OrdersCard from "../components/orderscard/OrdersCard";

export default function Orders() {
  const [orderedData, setOrderedData] = useState([]);
  const [isBegin, setIsBegin] = useState(true);
  const [isComplete, setIsComplete] = useState(true);

  useEffect(() => {
    try {
      const q = query(
        collection(db, "orders"),
        where("orderCompleted", "==", false)
      );

      onSnapshot(q, (querySnapshot) => {
        const orders = [];
        querySnapshot.docs.forEach((doc) => {
          orders.push({ ...doc.data(), id: doc.id });
        });
        setOrderedData(orders);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleProcessOrder = (orderId, menuId) => {
    updateFlashOrder(orderId);

    updateFlashMenuData(menuId, orderId);
    setIsBegin(false);
  };

  async function updateFlashOrder(id) {
    try {
      const updateOrderRef = doc(db, "orders", id);
      await updateDoc(updateOrderRef, {
        isFlashOrder: false,
      });

      console.log("FLASH ORDER COMPLETED SUCCESSFULLY");
    } catch (error) {
      console.log(error);
    }
  }

  async function updateFlashMenuData(id, orderId) {
    try {
      const updateMenuDataRef = doc(db, "foodmenu", id);
      await updateDoc(updateMenuDataRef, {
        isFlashOrder: true,
        orderId,
      });
    } catch (error) {
      console.log(error);
    }
  }

  async function completeOrder(id) {
    try {
      const updateMenuDataRef = doc(db, "foodmenu", id);
      await updateDoc(updateMenuDataRef, {
        isFlashOrder: false,
        isOrderComplete: true,
      });
      setIsComplete(false);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <OrdersCard
      orderData={orderedData}
      onHandleProcessOrder={handleProcessOrder}
      onOrderComplete={completeOrder}
      isBegin={isBegin}
      isComplete={isComplete}
    />
  );
}
