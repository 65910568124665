import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  collection,
  setDoc,
  doc,
  onSnapshot,
  query,
  updateDoc,
} from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";

import FoodMenuCard from "../components/food menu/FoodMenuCard";
import { db } from "../firebase/firebase";

import ConfirmationPrompt from "../components/popup/ConfirmationPrompt";

import "./Menu.css";

export default function Menu() {
  const [fooddata, setFoodData] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]);
  const [isSide, setIsSide] = useState(false);
  const [isOrderPlaced, setIsOrderPlaced] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [viewCart, setViewCart] = useState(false);

  const navigate = useNavigate();

  const { state } = useLocation();

  if (!state) navigate("/dashboard");

  useEffect(() => {
    try {
      const q = query(collection(db, "foodmenu"));

      onSnapshot(q, (querySnapshot) => {
        const menudata = [];
        querySnapshot.docs.forEach((doc) => {
          menudata.push({ ...doc.data(), id: doc.id });
        });
        setFoodData(menudata);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const ordersHandler = async (orderedData) => {
    try {
      const docId = uuidv4();
      await setDoc(doc(db, "orders", docId), {
        orders: orderedData,
        isFlashOrder: true,
        orderCompleted: false,
      });

      setIsOrderPlaced(true);
      setViewCart(false);
      setConfirmationMessage("Order was submitted successfully");
    } catch (err) {
      alert(err);
    }
  };

  async function updateFlashMenuData(id) {
    try {
      const updateMenuDataRef = doc(db, "foodmenu", id);
      await updateDoc(updateMenuDataRef, {
        isFlashOrder: false,
      });
    } catch (error) {
      console.log(error);
    }
  }

  async function completeOrder(id, orderId) {
    try {
      updateFlashCompleteMenuData(id);
      updateCompleteOrder(orderId);

      setConfirmationMessage("Order was completed successfully");
    } catch (error) {
      console.log(error);
    }
  }

  async function updateCompleteOrder(orderId) {
    try {
      const updateOrderRef = doc(db, "orders", orderId);
      await updateDoc(updateOrderRef, {
        orderCompleted: true,
        datetimecompleted: Date.now(),
      });
    } catch (error) {
      console.log(error);
    }
  }

  async function updateFlashCompleteMenuData(id) {
    try {
      const updateMenuDataRef = doc(db, "foodmenu", id);
      await updateDoc(updateMenuDataRef, {
        isOrderComplete: false,
      });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="menu">
      {isOrderPlaced && (
        <ConfirmationPrompt confirmation={confirmationMessage} />
      )}

      <FoodMenuCard
        menudata={fooddata}
        errorMessages={errorMessages}
        tableNum={state?.result.data}
        orderCompleted={false}
        ordersHandler={ordersHandler}
        isSide={isSide}
        setIsSide={setIsSide}
        onUpdateFlashMenuData={updateFlashMenuData}
        onCompleteOrder={completeOrder}
        viewCart={viewCart}
        setViewCart={setViewCart}
      />
    </div>
  );
}
