import React, { useState } from "react";
import { FaPlus, FaStopCircle, FaCheck } from "react-icons/fa";

import FoodSide from "./FoodSide";
import FoodCart from "./FoodCart";
import Popup from "../popup/Popup";
import useOrder from "../../context/OrderContext";

import "./FoodMenuCard.css";

function FoodMenuCard({
  menudata,
  errorMessages,
  ordersHandler,
  tableNum,
  onUpdateFlashMenuData,
  onCompleteOrder,
  viewCart,
  setViewCart,
}) {
  const [currentlyclicked, setCurrentlyClicked] = useState("");
  const [sideDetails, setSideDetails] = useState([]);

  const { addToCart } = useOrder();

  const [isAdded, setIsAdded] = useState(false);

  return (
    <div className="foodcard-container">
      {isAdded && (
        <Popup
          message="Item was added to the cart. Please click on cart icon to view item."
          onIsAdded={setIsAdded}
        />
      )}

      <FoodCart
        onSetViewCart={setViewCart}
        viewCart={viewCart}
        onOrderHandler={ordersHandler}
      />

      {menudata &&
        menudata.length > 0 &&
        menudata.map(
          ({
            id,
            category,
            price,
            description,
            imageUrl,
            sides,
            isFlashOrder,
            isOrderComplete,
            orderId,
          }) => {
            return (
              <div
                className={`food-card-sidebar ${
                  isFlashOrder && "blink_new_order"
                } ${isOrderComplete && "order-complete "}`}
                key={id}
              >
                <aside
                  className="food-card"
                  key={id}
                  onClick={() => setCurrentlyClicked(id)}
                >
                  {errorMessages.length > 0 &&
                    errorMessages.map((error) => (
                      <p className="error-message" key={error}>
                        {error}
                      </p>
                    ))}
                  <div>
                    <h3>{category}</h3>
                    <p>{description}</p>
                    <h3>&#36; {price}</h3>
                  </div>
                  <div className="image-container">
                    <img src={imageUrl} alt="food" loading="lazy" />
                  </div>
                  <div className="btn-submit-container">
                    {!isAdded && (
                      <button
                        className="submit-order"
                        onClick={() => {
                          setIsAdded(true);

                          addToCart({
                            id,
                            category,
                            price,
                            description,
                            tableNum,
                            imageUrl,
                            sideDetails,
                          });
                        }}
                      >
                        <FaPlus className="cart-icon" />
                      </button>
                    )}
                    {isFlashOrder && (
                      <button
                        className="submit-order"
                        onClick={onUpdateFlashMenuData.bind(this, id)}
                      >
                        <FaStopCircle className="cart-icon" />
                      </button>
                    )}

                    {isOrderComplete && (
                      <button
                        className="submit-order"
                        onClick={onCompleteOrder.bind(this, id, orderId)}
                      >
                        <FaCheck className="cart-icon" />
                      </button>
                    )}
                  </div>
                </aside>
                <main className="food-side">
                  {id === currentlyclicked && sides.length > 0 && (
                    <FoodSide
                      docId={id}
                      setSideDetails={setSideDetails}
                      sides={sides}
                      onIsAdded={setIsAdded}
                    />
                  )}
                </main>
              </div>
            );
          }
        )}
    </div>
  );
}

export default React.memo(FoodMenuCard);
