import { useEffect, useState } from "react";

import { getPhotos } from "../../firebase/fbcrud";
import "./FoodImages.css";

function FoodImage() {
  const [photos, setPhotos] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    (async () => {
      try {
        await getPhotos(setPhotos, setErrorMessage);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return (
    <div className="food-image-container">
      <p>{errorMessage !== "" ? errorMessage : undefined}</p>

      <Photos photos={photos} />
    </div>
  );
}

function Photos({ photos }) {
  return (
    <div className="photos">
      {photos.map(({ id, imageUrl }) => {
        if (imageUrl.includes(".mp4") || imageUrl.includes(".webm")) {
          return (
            <div key={id}>
              <video controls preload="none">
                <source src={imageUrl} />
                <source src={imageUrl} />
              </video>
            </div>
          );
        }
        return (
          <div key={id}>
            <img
              src={imageUrl}
              alt="Rapadou Caribbean Restaurant"
              loading="lazy"
            />
          </div>
        );
      })}
    </div>
  );
}

export default FoodImage;
