import React from "react";
import Navigation from "../components/navigation/Navigation";
export default function ErrorPage() {
  return (
    <>
      <Navigation />
      <main>
        <h1>An error occured!</h1>
        <p>Could not find this page!</p>
      </main>
    </>
  );
}
