import React from "react";
import { MdOutlineLocalPhone, MdEmail } from "react-icons/md";
import { CiInstagram, CiFacebook } from "react-icons/ci";
import { LiaMapMarkerAltSolid } from "react-icons/lia";

import "./Footer.css";

export default function Footer() {
  return (
    <footer className="footer">
      <div>
        <div className="footer-sides-container">
          <div className="footer-left-side">
            <p>
              <MdOutlineLocalPhone /> &#43; (717) 863-0507
            </p>
            <p>
              <MdEmail /> rapadoucaribbeanresto@yahoo.com
            </p>
          </div>

          <div className="footer-right-side">
            <p>
              <LiaMapMarkerAltSolid />
              1413 W. Market Street
            </p>
            <p>York Pennsylvania 17404</p>
          </div>
        </div>
        <div className="footer-social-icon-container">
          <p>
            <CiFacebook className="social-icon" />{" "}
            <CiInstagram className="social-icon" />
            Rapadou Caribbean Restaurant
          </p>
        </div>
      </div>
    </footer>
  );
}
