import React from "react";

import { useLocation } from "react-router-dom";

import QRCode from "react-qr-code";

import "./QRcodegenerator.css";

export default function QRcodegenerator() {
  const {
    state: { tableNum },
  } = useLocation();

  return (
    <div className="qr-code">
      {
        <QRCode
          value={`Table ${tableNum}`}
          size={150}
          style={{ height: "50rem", maxWidth: "100%", width: "60%" }}
        />
      }
    </div>
  );
}
