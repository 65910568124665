import React, { useRef, useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

import {
  getAllData,
  getInitialData,
  updateIncDecQuantity,
} from "../../firebase/fbcrud";
import { paginatedList } from "../../firebase/pagination";

import { saveInventory } from "../../firebase/fbcrud";
import ConfirmationPrompt from "../popup/ConfirmationPrompt";
import InventoryRow from "./InventoryRow";

import "./dashboard.css";

export default function Inventory() {
  const nameRef = useRef(null);
  const priceRef = useRef(null);
  const quantityRef = useRef(0);
  const btnNextRef = useRef(null);
  const btnPrevRef = useRef(null);

  const [errorMessage, setErrorMessage] = useState("");
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [isStoring, setIsStoring] = useState(true);

  const [inventoryData, setInventoryData] = useState([]);
  const [initialData, setInitialData] = useState([]);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(2);

  useEffect(() => {
    getAllData("inventory", setInventoryData);
  }, []);

  useEffect(() => {
    getInitialData("inventory", setInitialData);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const name = nameRef.current.value;
    const price = priceRef.current.value;
    const quantity = quantityRef.current.value;

    if (name === "") {
      setErrorMessage("Enter product name");
      return;
    }

    if (price === "") {
      setErrorMessage("Enter price");
      return;
    }

    if (quantity === "") {
      setErrorMessage("Enter quantity");
      return;
    }
    const id = uuidv4();
    await saveInventory(id, name, price, quantity, setConfirmationMessage);
    nameRef.current.value = "";
    priceRef.current.value = "";
    quantityRef.current.value = "";
  };

  const handleNext = () => {
    setCurrentPage((page) => page + 1);

    const data = paginatedList(
      inventoryData,
      3,
      currentPage,
      btnNextRef,
      btnPrevRef,
      setCurrentPage
    );
    setData(data);
  };

  const handlePrevious = () => {
    setCurrentPage((page) => page - 1);

    const data = paginatedList(
      inventoryData,
      3,
      currentPage,
      btnNextRef,
      btnPrevRef,
      setCurrentPage
    );
    setData(data);
  };

  const incDecQuantityHandler = (id, flag) => {
    updateIncDecQuantity(id, flag, setConfirmationMessage);
  };

  return (
    <div className="inventory">
      <p className="inventory-error-msg">
        {errorMessage !== "" ? errorMessage : null}
      </p>
      {confirmationMessage !== "" && (
        <ConfirmationPrompt confirmation={confirmationMessage} />
      )}
      <ul className="inventory-menu">
        <li onClick={() => setIsStoring(true)}>Store</li>
        <li onClick={() => setIsStoring(false)}>View</li>
      </ul>
      {isStoring ? (
        <form className="inventory-form" onSubmit={handleSubmit}>
          <div className="input-container">
            <input type="text" placeholder="Product name" ref={nameRef} />
            <input type="number" placeholder="Price" ref={priceRef} />
            <input type="number" placeholder="Quantity" ref={quantityRef} />
          </div>
          <input type="submit" value="Submit" />
        </form>
      ) : (
        <div className="category-view">
          <table>
            <thead>
              <tr>
                <td>Name</td>
                <td>Price</td>
                <td>Quantity</td>
                <td>(+/-) Quantity</td>
              </tr>
            </thead>
            <tbody>
              {data?.length > 0
                ? data.map(({ id, name, price, quantity }) => {
                    return (
                      <InventoryRow
                        id={id}
                        name={name}
                        price={price}
                        quantity={quantity}
                        onIncDecQuantity={incDecQuantityHandler}
                      />
                    );
                  })
                : initialData.length > 0 &&
                  initialData.map(({ name, price, quantity }) => {
                    return (
                      <tr>
                        <td>{name}</td>
                        <td>{price}</td>
                        <td>{quantity}</td>
                      </tr>
                    );
                  })}
              <tr>
                <td className="next-previous-wrapper">
                  <button onClick={handleNext} ref={btnNextRef}>
                    Next
                  </button>
                  <button onClick={handlePrevious} ref={btnPrevRef}>
                    Previous
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}
