import React, { useRef, useState, useEffect } from "react";

import { doc, getDoc, deleteDoc } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";

import { IoMdClose } from "react-icons/io";

import { db } from "../../firebase/firebase";
import {
  handleImageUpload,
  deleteSideImages,
  deleteImageFile,
  getAllData,
  getInitialData,
} from "../../firebase/fbcrud";
import { paginatedList } from "../../firebase/pagination";

import Row from "./Row";
import Modal from "../modal/Modal";

import "./dashboard.css";

export default function Category(props) {
  const { onIsCategory } = props;
  const foodTypeRef = useRef("");
  const foodPriceRef = useRef(0);
  const foodDescriptionRef = useRef("");
  const btnNextRef = useRef(null);
  const btnPrevRef = useRef(null);

  const updateTypeRef = useRef("");
  const updatePriceRef = useRef(0);
  const updateDescriptionRef = useRef("");
  const updateImageRef = useRef(null);
  const [imageFile, setImageFile] = useState(null);

  const [errorMessages, setErrorMessages] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [uploadProgress, setUploadProgress] = useState("");

  const [sideDescription, setSideDescription] = useState("");
  const [sideImage, setSideImage] = useState(null);

  const [isSide, setIsSide] = useState(false);

  const [foodData, setFoodData] = useState([]);
  const [initialData, setInitialData] = useState([]);
  const [data, setData] = useState([]);
  const [isUpdate, setIsUpdate] = useState(true);
  const [foodSide, setFoodSide] = useState([]);
  const [isCategoryForm, setIsCategoryForm] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    getAllData("foodmenu", setFoodData);
  }, []);

  useEffect(() => {
    getInitialData("foodmenu", setInitialData);
  }, []);

  const onSaveData = async (event) => {
    event.preventDefault();

    if (foodTypeRef.current.value === "") {
      setErrorMessages((error) => {
        return [...error, "Food type cannot be empty"];
      });
      return;
    }

    if (foodPriceRef.current.value === "") {
      setErrorMessages((error) => {
        return [...error, "Food Price cannot be empty"];
      });
      return;
    }

    if (foodDescriptionRef.current.value === "") {
      setErrorMessages((error) => {
        return [...error, "Food Description cannot be empty"];
      });
      return;
    }

    if (!imageFile) {
      setErrorMessages((error) => {
        return [...error, "You must upload image of the food"];
      });
      return;
    }

    if (isSide) {
      if (sideDescription === "") {
        setErrorMessages((error) => {
          return [...error, "You forgot to enter a side"];
        });
        return;
      }

      if (!sideImage) {
        setErrorMessages((error) => {
          return [...error, "You must upload an image for the side"];
        });
        return;
      }
    }

    const docId = uuidv4();
    const filename = uuidv4();
    const foodtype = foodTypeRef.current.value;
    const price = foodPriceRef.current.value;
    const description = foodDescriptionRef.current.value;

    await handleImageUpload(
      filename,
      imageFile,
      foodtype,
      price,
      description,
      docId,
      false,
      false,
      onIsCategory,
      setUploadProgress,
      setErrorMessages,
      setSuccessMessage,
      null,
      null,
      null,
      foodTypeRef,
      foodPriceRef,
      foodDescriptionRef,
      false,
      undefined,
      foodSide,
      description
    );
    setErrorMessages([]);
  };

  const updateFoodMenu = (id) => {
    try {
      const filename = uuidv4();
      const foodtype = updateTypeRef.current.value;
      const price = updatePriceRef.current.value;
      const description = updateDescriptionRef.current.value;

      const isImage = imageFile ? true : false; //we check for image/if user updates the image. If it does, we return true otherwise false

      handleImageUpload(
        filename,
        imageFile,
        foodtype,
        price,
        description,
        id,
        true,
        isImage,
        onIsCategory,
        setUploadProgress,
        setErrorMessages,
        setSuccessMessage
      );

      setErrorMessages([]);
      setSuccessMessage("The record was updated successfully");
    } catch (errorfb) {
      setErrorMessages([]);
      setErrorMessages((error) => {
        return [...error, errorfb];
      });
    }
  };

  const deleteFoodMenu = async (id) => {
    try {
      await getImageUrl(id);

      await deleteDoc(doc(db, "foodmenu", id));

      setErrorMessages([]);
      setSuccessMessage("The record was deleted successfully ");
    } catch (errorfb) {
      setErrorMessages([]);
      setErrorMessages((error) => {
        return [...error, errorfb];
      });
    }
  };

  async function getImageUrl(id) {
    try {
      //We get the document by id then we extract the image Url from the document
      const docRef = doc(db, "foodmenu", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        deleteImageFile(
          docSnap.data().imageUrl,
          setSuccessMessage,
          setErrorMessages
        );
        // deleteSideImages(docSnap.data().sides);
        deleteSideImages(
          docSnap.data().sides,
          setSuccessMessage,
          setErrorMessages
        );
      } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }
    } catch (err) {
      console.log(err.message);
    }
  }

  const addSidesHandler = async (event) => {
    event.preventDefault();

    const filename = uuidv4();
    const docId = uuidv4();

    await handleImageUpload(
      filename,
      sideImage,
      "",
      "",
      "",
      docId,
      false,
      false,
      false,
      setUploadProgress,
      setErrorMessages,
      setSuccessMessage,
      null,
      null,
      null,
      null,
      null,
      null,
      true,
      setFoodSide,
      foodSide,
      sideDescription
    );

    setIsSide(false);
  };

  const handleNext = () => {
    try {
      setCurrentPage((page) => page + 1);

      const data = paginatedList(
        foodData,
        3,
        currentPage,
        btnNextRef,
        btnPrevRef,
        setCurrentPage
      );
      setData(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePrevious = () => {
    setCurrentPage((page) => page - 1);

    const data = paginatedList(
      foodData,
      3,
      currentPage,
      btnNextRef,
      btnPrevRef,
      setCurrentPage
    );
    setData(data);
  };

  return (
    <div>
      {uploadProgress !== "" && (
        <p className="success-message">{uploadProgress}</p>
      )}
      {successMessage !== "" && (
        <p className="success-message">{successMessage}</p>
      )}

      {errorMessages.length > 0 &&
        errorMessages.map((error) => (
          <p className="error-message" key={error}>
            {error.toString()}
          </p>
        ))}
      <div className="category">
        {isCategoryForm && (
          <form className="food-category" onSubmit={onSaveData}>
            <IoMdClose
              className="popup-close"
              onClick={() => setIsCategoryForm(false)}
            />
            <input
              type="text"
              placeholder="Enter Type of Food"
              ref={foodTypeRef}
            />
            <input type="number" placeholder="Enter price" ref={foodPriceRef} />

            <input
              id="file-upload"
              type="file"
              onChange={(event) => setImageFile(event.target.files[0])}
              accept="image/png, image/jpeg"
            />
            <textarea
              ref={foodDescriptionRef}
              spellCheck="true"
              placeholder="Enter a description"
            ></textarea>
            <div className="sides">
              <label>Do you need to add sides to this category of Food ?</label>
              <div>
                <label htmlFor="yes" className="container">
                  Yes
                  <input
                    type="radio"
                    id="yes"
                    name="sides"
                    value="Yes"
                    onClick={(e) => {
                      setIsSide(true);
                    }}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div>
                <label htmlFor="no" className="container">
                  No
                  <input
                    type="radio"
                    id="no"
                    name="sides"
                    value="No"
                    onClick={(e) => setIsSide(false)}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
            {isSide && (
              <>
                <Modal
                  addSidesHandler={addSidesHandler}
                  setIsSide={setIsSide}
                  setSideDescription={setSideDescription}
                  setSideImage={setSideImage}
                />
                <input
                  id="file-upload"
                  type="file"
                  onChange={(event) => setSideImage(event.target.files[0])}
                  accept="image/png, image/jpeg"
                />
              </>
            )}
            <div>
              <input type="submit" value="Submit" />
            </div>
          </form>
        )}
        <div className="category-view">
          <table id="menu-item">
            <thead>
              <tr>
                <td>Type</td>
                <td>Price</td>
                <td>Description</td>
                <td>Image</td>
                <td>Side Description</td>
                <td>Side Image</td>
                {isUpdate ? (
                  <>
                    <td>Update </td>
                    <td>Delete</td>
                  </>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {data?.length > 0
                ? data.map((data) => {
                    return (
                      <Row
                        key={data.id}
                        data={data}
                        onIsUpdate={setIsUpdate}
                        isUpdate={isUpdate}
                        updateTypeRef={updateTypeRef}
                        updatePriceRef={updatePriceRef}
                        updateDescriptionRef={updateDescriptionRef}
                        updateImageRef={updateImageRef}
                        onUpdateFoodMenu={updateFoodMenu}
                        onDeleteFoodMenu={deleteFoodMenu}
                        setImageFile={setImageFile}
                      />
                    );
                  })
                : initialData.length > 0 &&
                  initialData.map((data) => {
                    return (
                      <Row
                        key={data.id}
                        data={data}
                        onIsUpdate={setIsUpdate}
                        isUpdate={isUpdate}
                        updateTypeRef={updateTypeRef}
                        updatePriceRef={updatePriceRef}
                        updateDescriptionRef={updateDescriptionRef}
                        updateImageRef={updateImageRef}
                        onUpdateFoodMenu={updateFoodMenu}
                        onDeleteFoodMenu={deleteFoodMenu}
                        setImageFile={setImageFile}
                      />
                    );
                  })}
              <tr>
                <td className="next-previous-wrapper">
                  <button onClick={handleNext} ref={btnNextRef}>
                    Next
                  </button>
                  <button onClick={handlePrevious} ref={btnPrevRef}>
                    Previous
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
