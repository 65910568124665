import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

import "./dashboard.css";

export default function QRcode() {
  const tableRef = useRef(0);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/qrcode", {
      state: { tableNum: tableRef.current.value },
    });
  };

  return (
    <div>
      <form className="qrcode" onSubmit={handleSubmit}>
        <input ref={tableRef} type="text" placeholder="Enter Table #" />
        <input type="submit" value="Generate QR Code" className="btn-submit" />
      </form>
    </div>
  );
}
