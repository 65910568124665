import React from "react";
//import Advertisement from "../components/ads/Advertisement";
import FoodImages from "../components/food image/FoodImages";
import Banner from "../components/banner/Banner";

import "./Home.css";

export default function Home() {
  return (
    <section className="home">
      <Banner />
      <FoodImages />
    </section>
  );
}
