import React from "react";
import { FaShoppingCart } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import useOrder from "../../context/OrderContext";
import CartCard from "./CartCard";

import "./FoodCart.css";

//food cart will be just the icon
//when user clicks on the icon to see the cart, the body of the card shoud slowly expand - meaning increase height at slow pace

function FoodCart({ onOrderHandler, onSetViewCart, viewCart }) {
  const { orders, removeFromCart } = useOrder();

  return (
    <>
      <FaShoppingCart
        onClick={() => onSetViewCart(!viewCart)}
        className="fa-shopping-cart"
      />
      {orders && orders.length > 0 ? (
        <p className="num-in-cart">{orders.length} </p>
      ) : null}

      {viewCart && (
        <div className="food-cart-container">
          <IoMdClose
            className="cart-icon cart-close"
            onClick={() => onSetViewCart(!viewCart)}
          />

          {viewCart && (
            <div className="food-cart">
              {orders.map((order) => (
                <CartCard
                  key={order.id}
                  order={order}
                  onRemoveFromCart={removeFromCart}
                />
              ))}

              {orders && orders.length > 0 && (
                <button
                  className="place-order"
                  onClick={onOrderHandler.bind(this, orders)}
                >
                  Place Order
                </button>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
}
export default React.memo(FoodCart);
