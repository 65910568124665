import { Outlet } from "react-router-dom";
import Navigation from "../components/navigation/Navigation";
import Footer from "./Footer";

export function RootLayout() {
  return (
    <>
      <Navigation />
      <main className="content">
        <Outlet />
      </main>
      <Footer />
    </>
  );
}
