import React, { useState } from "react";
import { IoMdClose } from "react-icons/io";

import "./confirmationPrompt.css";

function ConfirmationPrompt({ confirmation }) {
  const [isClose, setIsClose] = useState(true);
  return (
    isClose && (
      <div className="confirmation-container">
        <IoMdClose
          className="icon-delete"
          onClick={() => setIsClose(!isClose)}
        />
        <p>{confirmation}</p>
      </div>
    )
  );
}

export default ConfirmationPrompt;
