import React from "react";
import { IoMdClose } from "react-icons/io";

import "./modal.css";

export default function Modal({
  addSidesHandler,
  setIsSide,
  setSideDescription,
  setSideImage,
}) {
  return (
    <div className="modal">
      <div>
        <button
          onClick={(e) => {
            e.preventDefault();
            setIsSide(false);
          }}
        >
          <IoMdClose className="react-icon" color="#c25d0d" />
        </button>
      </div>
      <form>
        <textarea
          onChange={(e) => setSideDescription(e.target.value)}
          spellCheck="true"
          placeholder="Enter description of the side(s)."
        ></textarea>

        <input
          id="file-upload"
          type="file"
          onChange={(event) => setSideImage(event.target.files[0])}
          accept="image/png, image/jpeg"
        />
        <div>
          <button onClick={addSidesHandler}>Submit</button>
        </div>
      </form>
    </div>
  );
}
